export const API_BASE_URL = "https://testapi203.cookandchef.in/api/v1";
// 'http://localhost:5001/api/v1'

    // process.env.API_BASE_URL || "https://testapi203.cookandchef.in/api/v1";
    


export const API_PATHS = {

    login: "/admin/login",
    cselogin:"/support/login",
adminforgotpassword:"/admin/forgotPassword",
adminchangepassword:"/admin/forgotPassword/verifyOtp",

    //plans
    getCookPlan: "/admin/cookplans/fetch",
    addCookPlan: "/admin/cookplans/store",
    deleteCookPlan:"/admin/cookplans/delete",
    editCookPlan:"/admin/cookplans/edit",


    getClientPlan: "/admin/clientplans/fetch",
    addClientPlan: "/admin/clientplans/store",
    deleteClientPlan:"/admin/clientplans/delete",
    editClientPlan:"/admin/clientplans/edit",

    getEmployerPlan: "/admin/employerplans/fetch",
    addEmployerPlan: "/admin/employerplans/store",
    deleteEmployertPlan:"/admin/employerplans/delete",
    editEmployerPlan:"/admin/employerplans/edit",
    editcatering:"/admin/edit/profile/catering",

    // cook
    getHouseCook: "/admin/find-house-cooks",
    getChef: "/admin/find-chefs",
    getPartyCook: "/admin/find-party-cooks",
    getCatering: "/admin/find-caterings",
    getCookProfile:"/admin/cook-details/fetch",
    getAllCookList :"/admin/allCooksList/fetch",
    changeRole :"admin/change/cookRole",
   
    //employer

    getEmployer: "/admin/find-employers",
    getEmployerProfile:"/admin/employer-details/fetch",
    editemployer :"/admin/employer-profile/edit",
    editcook :"/admin/cook-profile/edit",
    editchef :"/admin/edit/profile/chef",
    editparty:"/admin/edit/profile/partyCook",
    edithousecook:"/admin/edit/profile/houseCook",

    getcookProfile:"/admin/cook-details/fetch",
    CancelorRefund :"/admin/cancelOrRefund/plans",
    UpgradeemployerPlan :"/admin/payment/upgradeEmployerPlan",
    editRequirement:"/admin/requirement/edit",
    getReportsList :"/admin/reports-list/fetch",
    shareProfile : "/admin/share/cookProfile",
    getSharedPrrofiels :"/admin/share/cookProfile/fetch",
    deleteSharedProfiles :"/admin/share/cookProfile/delete",



    //testimonial
    alltestimonials :"/admin/testimonials/fetch",
    edittestimonial :"/admin/testimonials/edit",
    deletetestimonial:"/admin/testimonials/delete",
    addtestimonial: "/admin/testimonials/add",
    uploadFile :`${API_BASE_URL}/admin/upload`,
   
    //client
    getClient: "/admin/find-clients",

  // jobs
  getJobs: "/admin/jobs-list/fetch",
  getJobsDetails : "/admin/job-details/fetch",
  editJob : "/admin/jobpost/edit",
  editEvent :"/admin/event/edit",
  sendnotifications:"/admin/trigger/jobNotification",
getApplicationslist :"/admin/applications-list/fetch",
 postJob : "/admin/job/add",
 postEvent : "/admin/event/add",
 jobstatus :"/admin/job/viewUpdate",


  //events
  getEvents:"/admin/events-list/fetch",
  getEventDetails:"/admin/event-details/fetch",
  senteventNotifications :"/admin/trigger/eventNotification",

//config
getCuisine:"/common/fetch/cusiniesList",
getQualification:"/dropdown/fetch-qualification",
getLanguages:"/dropdown/fetch-languages",
getState:"/dropdown/fetch-province",
resetpassword:"/admin/reset-password",
suspendAccount:"/admin/suspend-account",
deleteAccount:"/admin/delete-account",
canceldeleteAccount:"/admin/revoke-delete-request",
activateAccount:"/admin/revoke-suspension",
addplan:"/admin/plans/allot",

//sales report
getPayments:"/admin/payments-list/fetch",


//faq
addfaq:"/admin/faq/add",
getfaq:"/admin/faq/fetch",
editfaq:"/admin/faq/edit",
deletefaq:"/admin/faq/delete",
getdashboardData :"/admin/dashboard/fetch",



//cse
getroles:"/admin/roles-list/fetch",
editcseaccounts:"/admin/csexecutives/edit",
getcseaccounts :"/admin/csexecutives-list/fetch",
getsinglecseaccount :"/admin/csexecutives-details/fetch",
addcseaccount :"/admin/csExecutive/create",
deleteCse:"/admin/csexecutives/delete",


//activity
getUserActivity:"/admin/activites/fetch",
getDeleteRequests:"/admin/disposal-accounts-list/fetch",
blockedProfile:"/admin/disposal-accounts-list/fetch",
getAllActivity :"/admin/allUsers/graph",
trackUsers :"https://api.interakt.ai/v1/public/apis/users/",
trackUsersAPIKEY : "OFJFVlNJOUdHaHV1WFB1RkF5d1NaeVdyS3J4QVE0cklBWVlTZC1iN2Rvazo=",

//create
createCuisine :"/dropdown/create-cuisines",
createQualification :"/dropdown/create-qualification",
createLanguage :"/dropdown/create-language",
createProvince :"/dropdown/create-province",

//advertisements
addAdvertisements :"/admin/advertisement/add",
getAdvertisements :"/admin/advertisement-list/fetch",
editAdvertisements :"/admin/advertisement/edit",
deleteAdvertisemets:"/admin/advertisement/delete",


//web support
websitesupport:"/admin/tickets/fetch",
blockedmaclist :"/admin/macaddress-list/fetch" ,
unblockmacaddress :"/admin/macaddress/unblock",
getallowedMacs :"/admin/allowedMacaddress-list/fetch",

allowmacaddress :"/admin/macaddress/allow",
googlemapsapikey :"AIzaSyCMMDmDdRD71ao1oc67wqhOGJ8k1D_JuhY"

};
